<template>
  <div class="body" v-loading="showLoading">
    <el-form size="medium" :model="configForm" label-position="right" label-width="70px"
      class="form small-form" v-if="configForm" @submit.native.prevent>
      <div class="modular" style="margin-top: 15px">
        <p class="modular-title" style="margin-bottom: 15px">心得评论审核设置</p>
        <div class="modular-content">
          <el-form-item label="审核设置：" prop="comment_check_type">
            <div style="margin-left: 20px">
              <el-radio v-model="configForm.comment_check_type" :label="1">先发后审</el-radio>
              <el-radio v-model="configForm.comment_check_type" :label="0">先审后发</el-radio>
            </div>
          </el-form-item>
          <el-form-item label="评论间隔：" prop="interval">
            <el-input-number style="margin: 0 20px;width: 150px" v-model.number="configForm.interval" :min="1"
              @change="intervalChange"></el-input-number>
            秒
          </el-form-item>
        </div>
      </div>
      <div class="modular" style="margin-top: 15px">
        <p class="modular-title" style="margin-bottom: 15px">心得内容过滤设置</p>
        <div class="modular-content">
          <el-form-item label="过滤设置：" prop="is_open_filter_word">
            <div style="margin-left: 20px">
              <el-radio v-model="configForm.is_open_filter_word" :label="1">开启</el-radio>
              <el-radio v-model="configForm.is_open_filter_word" :label="0">关闭</el-radio>
            </div>
          </el-form-item>
          <el-form-item label="过滤词库：" prop="FilterWords" v-if="configForm.is_open_filter_word">
            <div style="margin-left: 20px">
              <el-radio v-model="filter_words" :label="0">全部</el-radio>
              <el-radio v-model="filter_words" :label="1">部分</el-radio>
            </div>
          </el-form-item>
          <el-form-item label="选择词库：" prop="filter_words" v-if="filter_words">
            <el-select v-model="configForm.filter_words" multiple filterable allow-create default-first-option
              placeholder="请选择词库">
              <el-option v-for="item in filterWordsOptions" :key="item.id" :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div class="modular" style="margin-top: 15px">
        <p class="modular-title" style="margin-bottom: 15px">心得、互动评论过滤设置</p>
        <div class="modular-content">
          <el-form-item label="过滤设置：" prop="is_open_tips_filter_word">
            <div style="margin-left: 20px">
              <el-radio v-model="configForm.is_open_tips_filter_word" :label="1">开启</el-radio>
              <el-radio v-model="configForm.is_open_tips_filter_word" :label="0">关闭</el-radio>
            </div>
          </el-form-item>
          <el-form-item label="过滤词库：" prop="FilterWords" v-if="configForm.is_open_tips_filter_word">
            <div style="margin-left: 20px">
              <el-radio v-model="filter_tips_words" :label="0">全部</el-radio>
              <el-radio v-model="filter_tips_words" :label="1">部分</el-radio>
            </div>
          </el-form-item>
          <el-form-item label="选择词库：" prop="filter_words" v-if="filter_tips_words">
            <el-select v-model="configForm.filter_tips_words" multiple filterable allow-create
              default-first-option placeholder="请选择词库">
              <el-option v-for="item in filterWordsOptions" :key="item.id" :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <FixedActionBar>
      <el-button type="primary" @click="handleSave" :loading="saveLoading">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import {
  ActivityCommentConfig,
  FilterWords,
  SaveCommentConfig,
} from "../../api/activity-config/comment-config";
import FixedActionBar from "@/base/layout/FixedActionBar";
export default {
  data() {
    return {
      configForm: null,
      filter_words: 0,
      filter_tips_words: 0,
      filterWordsOptions: [],
      old_interval: 0,
      showLoading: false,
      saveLoading: false,
    };
  },
  methods: {
    getActivityCommentConfig() {
      this.showLoading = true;
      ActivityCommentConfig()
        .then((res) => {
          this.configForm = res.data;
          this.old_interval = this.configForm.interval;
          this.filter_words = this.configForm.filter_words.length ? 1 : 0;
          this.filter_tips_words = this.configForm.filter_tips_words.length
            ? 1
            : 0;
          this.getFilterWords();
          this.showLoading = false;
        })
        .catch((err) => {
          this.showLoading = false;
        });
    },
    // 获取所有词库
    getFilterWords() {
      FilterWords().then((res) => {
        this.filterWordsOptions = res.data;
      });
    },
    // 评论间隔
    intervalChange(value) {
      if (value === undefined) {
        this.$nextTick(() => {
          this.configForm.interval = this.old_interval;
        });
      } else {
        this.old_interval = value;
      }
    },
    // 保存
    handleSave() {
      this.saveLoading = true;
      const data = {};
      data.config = { ...this.configForm };
      SaveCommentConfig(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.saveLoading = false;
        })
        .catch((err) => {
          this.saveLoading = false;
        });
    },
  },
  created() {
    this.getActivityCommentConfig();
  },
  components: { FixedActionBar },
};
</script>

<style lang="scss" scoped>
.body {
  position: relative;

  .form-title {
    padding: 27px 0 19px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid #e9e9e9;
  }
  .el-input {
    width: 50px;
  }

  .bottom-btn {
    padding: 0 0 10px 20px;
    position: absolute;
    bottom: 0;
  }
}
</style>