import api from '@/base/utils/request';

// 获取评论配置
export const ActivityCommentConfig = data => {
  return api({
    url: '/admin/cyc_activity/ActivitySetting/getActivityCommentConfig',
    method: 'post',
    data
  });
};

// 获取所有词库
export const FilterWords = data => {
  return api({
    url: '/admin/admin/filter_word/all',
    method: 'post',
    data
  });
};

// 保存评论配置
export const SaveCommentConfig = data => {
  return api({
    url: '/admin/cyc_activity/ActivitySetting/setActivityCommentConfig',
    method: 'post',
    data
  });
};